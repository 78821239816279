.search-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}
.search-content {
  display: flex;
  flex-grow: 1;
  padding: 20px;
  width: 100%;
}
.search-button{
  background: none;      
  border: none;         
  padding: 0;          
  margin: 0;            
  cursor: pointer;      
  outline: none;         
}

.search-content > *:first-child {
  width: 250px;
  margin-right: 20px;
}

.search-content > *:last-child {
  flex-grow: 1;
  max-width: calc(100% - 270px);
}

.search-results {
  display: flex;
  flex-wrap: wrap;
}
