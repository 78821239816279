.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  padding-right: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
  transition: all 0.3s ease;
  opacity: 1;
  transform: translateX(0);
}

.cart-item.fade-out {
  opacity: 0;
  transform: translateX(20px);
}

.cart-item-image {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.cart-item-details {
  flex: 1;
}

.cart-item-name {
  font-weight: bold;
  font-size: 15px;
}

.cart-item-description {
  color: #7f7f7f;
  margin: 5px 0;
}

.cart-item-price-section {
  margin-top: 10px;
}

.cart-item-discounted-price {
  text-decoration: line-through;
  color: #999;
  margin-left: 10px;
}

.cart-item-actions {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.cart-item-quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-decrease, .quantity-increase {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cart-item-quantity {
  margin: 0 10px;
}

.cart-buy-now-button {
  background: none;
  color: #FF9900;
  border: 1px solid #FF9900;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  margin-left: 20px;
  cursor: pointer;

}


.cart-item-icons {
  display: flex;
  align-items: center;
}

.icon-heart {
  cursor: pointer;
  font-size: 20px;
  margin-right: 15px;
}

.cart-item-remove-icon {
  color: #666;
  cursor: pointer;
  font-size: 20px;
}
.cart-item-remove-icon:hover {
  color: #ff4800;
}

.cart-item-unit-price {
  color: #666;
  font-size: 0.9em;
  margin-left: 8px;
}

.cart-item-price {
  font-weight: bold;
  color: #000;
}

