:root {
  --primary-green: #4caf50;
  --dark-green: #4caf50;
  --green-light: #93C572;
  --light-green: #f7faf8;
  --text-dark: #333333;
  --text-light: #666666;
  --background-light: #FFFFFF;
  --border-color: #E0E0E0;
  --status-success: #4CAF50;  /* Green for success/paid */
  --status-pending: #FF9800;  /* Orange for pending */
  --status-failed: #F44336;  /* Red for failed/unpaid */
  --status-processing: #2196F3;  /* Blue for processing */
  --status-shipped: #9C27B0;  /* Purple for shipped */
  --status-delivered: #4CAF50;  /* Green for delivered */
  --status-cancelled: #757575;  /* Grey for cancelled */
}

.order-details-container {
  width: 100%;
  padding: 20px;
  background: var(--background-light);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.order-details-container button{
color: var(--primary-green);
}
.order-details-section {
  display: flex;
  align-items: center;
  background-color: var(--light-green);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--border-color);
}

.order-details-icon {
  font-size: 24px;
  color: var(--primary-green);
  margin-right: 15px;
}

.order-details-content {
  display: flex;
  flex-direction: column;
}

.order-details-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 10px;
}

.order-details-text {
  font-size: 14px;
  color: var(--text-light);
  margin-bottom: 5px;
}
.order-details-date {
  font-size: 16px;
  color: var(--text-light);
  margin-bottom: 5px;
}
.order-details-momo-code{
  font-weight: 600;
}
.order-details-status {
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--text-dark);
}

.order-details-status-text {
  font-size: 14px;
  font-weight: 600;
  color: var(--background-light);
  padding: 5px 10px;
  margin-left: 10px;
}

/* Payment Status Colors */
.order-details-status-text.paid {
  border: 1px solid var(--status-success);
  color: var(--status-success);
}

.order-details-status-text.unpaid {
  border: 1px solid var(--status-failed);
  color: var(--status-failed);
}

.order-details-status-text.pending {
  border: 1px solid var(--status-pending);
  color: var(--status-pending);
}

/* Order Status Colors */
.order-details-status-text.processing {
  background-color: var(--status-processing);
}

.order-details-status-text.shipped {
  background-color: var(--status-shipped);
}

.order-details-status-text.delivered {
  background-color: var(--status-delivered);
}

.order-details-status-text.cancelled {
  background-color: var(--status-cancelled);
}

.order-details-formore-info{
  font-size: 14px;
  color: var(--text-light);
}
.order-details-summary,
.order-details-items-ordered
 {
  background-color: var(--light-green);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--border-color);
  width: 100%;
  margin: 0 auto;
}
.order-details-courier-info{
background-color: white;
border: 1px solid var(--border-color);
padding: 20px;
}

.order-content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.order-details-summary,
.order-details-items-ordered {
  background-color: var(--light-green);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--border-color);
  width: 100%;
  margin: 0;
}

.order-details-courier-info {
  grid-column: 1 / -1;
}

.order-details-summary-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--text-dark);
  margin-bottom: 10px;
}

.order-details-summary-row.total {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-green);
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--primary-green);
}

.order-details-ordered-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

.order-details-ordered-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.order-details-item-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.order-details-item-details {
  display: flex;
  flex-direction: column;
}

.order-details-item-name {
  font-size: 16px;
  color: var(--text-dark);
  font-weight: 600;
  margin-bottom: 5px;
}

.order-details-item-quantity,
.order-details-item-price {
  font-size: 14px;
  color: var(--text-light);
}

.order-details-back-button {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  background: none;
  border: none;
  color: var(--text-dark);
  cursor: pointer;
}

.order-details-loading-message,
.order-details-no-order-message,
.order-details-error-message {
  font-size: 16px;
  text-align: center;
  color: var(--text-light);
  margin: 20px 0;
  grid-column: 1 / -1;
}

.contact-us-button {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-green);
  border: 1px solid var(--primary-green);
  background-color: var(--background-light);
  padding: 5px 10px;
  /* border-radius: 5px; */
  margin-left: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: var(--primary-green);
  color: var(--background-light);
}

@media (max-width: 768px) {
  .order-details-container {
    grid-template-columns: 1fr;
    padding: 15px;
  }

  .order-details-summary,
  .order-details-items-ordered,
  .order-details-courier-info {
    grid-column: 1 / 2;
  }

  .order-details-ordered-item {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .order-details-item-image {
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  .order-details-header-content {
    flex-direction: column;
    gap: 20px;
  }

  .order-status-badges {
    width: 100%;
    justify-content: flex-start;
  }

  .status-badge {
    align-items: flex-start;
  }

  .payment-info-footer {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .order-content-grid {
    grid-template-columns: 1fr;
  }
}

.order-details-header {
    grid-column: 1 / -1;
    margin-bottom: 20px;
}

.order-details-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
}

.order-details-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-dark);
    margin-bottom: 8px;
}

.order-status-badges {
    display: flex;
    padding: 1rem 0rem 0rem;
    gap: 20px;
}

.status-badge {
    display: flex;
    flex-direction: column;
}
.status-label {
    font-size: 12px;
    color: var(--text-light);
    margin-bottom: 4px;
}
.order-details-status-text{
    align-items: flex-start;
    margin-left: 0;
}
.payment-instructions-card {
    grid-column: 1 / -1;
    background-color: var(--light-green);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-bottom: 30px;
}

.payment-instructions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid var(--border-color);
}

.payment-instructions-header h3 {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-dark);
    margin: 0;
}

.payment-pending-badge {
    background-color: var(--status-pending);
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.payment-instructions-content {
    padding: 20px;
}

.momo-code-section {
    display: flex;
    align-items: center;
        gap: 15px;
        padding: 15px;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 15px;
}

.momo-label {
    color: var(--text-light);
    font-size: 14px;
}

.momo-code {
    font-family: monospace;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-dark);
    padding: 4px 8px;
    background-color: var(--light-green);
    border-radius: 4px;
}

.merchant-name {
    font-weight: 500;
    color: var(--text-dark);
}

.payment-info-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.payment-note {
    color: var(--text-light);
    font-size: 14px;
    margin: 0;
}

.contact-support-btn {
    background: none;
    border: 1px solid var(--primary-green);
    color: var(--primary-green);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contact-support-btn:hover {
    background-color: var(--primary-green);
    color: white;
}

