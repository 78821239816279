.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

.notification-content {
  display: flex;
  align-items: center;
  background: white;
  padding: 16px 24px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-width: 300px;
}

.notification-icon {
  margin-right: 12px;
}

.success-icon {
  color: #4CAF50;
  font-size: 20px;
}

.notification-message {
  flex: 1;
  margin: 0;
  font-size: 14px;
  color: #333;
}

.notification-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 12px;
}

.notification-action-button {
    background: rgba(222, 184, 135, 0.1);
    border: none;
    color: #DEB887;
    font-weight: 600;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 3px;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.notification-close {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}