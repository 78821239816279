:root {
  --tp-primary-green: #93C572;
  --tp-dark-green: #7AAC5A;
  --tp-light-green: #E8F5E0;
  --tp-text-dark: #333333;
  --tp-text-light: #666666;
  --tp-background-light: #FFFFFF;
  --tp-border-color: #E0E0E0;
}

.tp-container {
  max-width: 1000px;
  margin: 30px auto;
  padding: 20px;
  background: var(--tp-background-light);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tp-main-title {
  font-size: 28px;
  font-weight: 600;
  color: var(--tp-text-dark);
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--tp-primary-green);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.2;
}

.tp-section {
  margin-bottom: 30px;
}

.tp-section-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--tp-primary-green);
  margin-bottom: 15px;
}

.tp-section-content {
  font-size: 16px;
  color: var(--tp-text-dark);
  line-height: 1.6;
}

.tp-section-content p {
  margin-bottom: 15px;
}

.tp-section-content ul, .tp-section-content ol {
  margin-left: 20px;
  margin-bottom: 15px;
}

.tp-section-content li {
  margin-bottom: 10px;
}

.tp-back-button {
  display: inline-flex;
  align-items: center;
  background-color: var(--tp-primary-green);
  color: var(--tp-background-light);
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.tp-back-button:hover {
  background-color: var(--tp-dark-green);
}

@media (max-width: 768px) {
  .tp-container {
    padding: 15px;
  }

  .tp-main-title {
    font-size: 24px;
  }

  .tp-section-title {
    font-size: 20px;
  }

  .tp-section-content {
    font-size: 14px;
  }
}
