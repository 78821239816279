.bestsellers-section {
  padding: 20px;
  margin-left: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}
.bestsellers-title-and-chevron {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.bestsellers-title {
  font-size: 22px;
  font-weight: 700;
  color: #222222;
  letter-spacing: -0.3px;
}

.bestsellers-chevrons {
  display: flex;
  gap: 0.75rem;
}

.bestsellers-chevrons .scroll-button {
  width: 28px;
  height: 28px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 16px;
}

.bestsellers-chevrons .scroll-button:hover {
  border-color: #999;
  color: #333;
}

.scroll-button {
  background-color: #ffff;
  border: none;
  color: #8b8989;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  font-weight: 200;
  margin-top: 30px;
  
}
.bestsellers-grid {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  width: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.bestsellers-grid::-webkit-scrollbar {
  display: none;
}

.bestsellers-grid:focus {
  outline: none;
}

.bestsellers-product-item {
  flex: 0 0 19%;
  width: 240px;
}
.nav-arrow {
  background:none;
  border: none;
  color: #222222;
  font-size: 16px;
  cursor: pointer;
  font-weight: 200;
}

.no-products-message {
  text-align: left;
  color: #666666;
  font-size: 16px;
  margin: 1rem 0;
}