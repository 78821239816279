.new-products-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.products-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.new-products-container {
    width: 100%;
}

.new-products-title {
    font-size: 1.7rem;
    margin-bottom: 10px;
    color: #000; 
} 

.new-products-grid {
  display: grid;
  grid-template-columns: repeat(5,1fr);
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 20px;
  padding: 10px 0;
}

.new-product-card {
  background: #fff;
  border: none;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.new-product-image {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 8px;
}

/* Add overlay on hover */
.new-product-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.new-product-card:hover .new-product-image::before {
  opacity: 1;
}

.new-product-tag {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #32cd32;
  color: #fff;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 2px;
}
.new-product-tag.hot-deal {
  background-color: #ff5050;
}

.new-product-overlay {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0;
  height: 100%;
  transition: opacity 0.3s ease;
  padding-bottom: 10px;
}

.new-product-card:hover .new-product-overlay {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.206);
}

.new-product-icon {
  color: #fff ;
  font-size: 1.5rem;
  margin-right: 8px;
}
.new-product-shop-btn {
  background-color: #49db49;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new-product-shop-btn.hot-deal {
  background-color: #ff5050;
}

.new-product-shop-btn:hover {
  background-color: #32cd32;
}

.new-product-shop-btn.hot-deal:hover {
  background-color: #ff3333;
}

.new-product-details {
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.new-product-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.new-product-name {
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 12px;
  color: #4d4a4a;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 36px;
}

.new-product-brand {
  font-size: 14px;
  font-weight: 700;
  margin: 0px 0 0px 0;
  color: #333;
  padding-right: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.new-product-rating {
  font-size: 12px;
  margin: 4px 0;
  color: #666;
  display: flex;
  align-items: center;
  gap: 4px;
}

.filled-star {
  color: #ffd700;
}

.outlined-star {
  color: #ddd;
}

.new-product-old-price {
  font-size: 13px;
  color: #999;
  margin: 0;
  text-decoration: line-through;
  line-height: 1.2;
}

.new-product-price {
  font-size: 15px;
  color: #ff5050;
  font-weight: 700;
  margin: 4px 0;
}

.new-product-gift {
  font-size: 0.875rem;
  color: #2ecc71;
  
}
.new-product-cart-icon, .new-product-cart-icon.hot-deal {
  font-size: 22px;
  color: #333;
  margin-bottom: 2px;
  transition: all 0.2s ease;
  cursor: pointer;
  opacity: 0.85;
}

.new-product-cart-icon:hover {
  transform: scale(1.05);
  opacity: 1;
  color: #32cd32;
}

.new-product-cart-icon.hot-deal:hover {
  color: #ff5050;
}

.new-product-wishlist-icon {
  font-size: 1.2rem;
  color: #32cd32;
  transition: color 0.3s ease;
  position: static;
  padding: 0;
  background-color: transparent;
}

.new-product-wishlist-icon.hot-deal {
  color: #ff5050;
}

.new-product-wishlist-icon:hover {
  color: #32cd32;
}

.new-product-wishlist-icon.hot-deal:hover {
  color: #ff5050;
}
.new-product-cart-container {
  display: flex;
  justify-content: flex-end;
}
.no-new-products-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; 
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  font-weight: bold;
  margin: 0; 
}
/* New styles for vertical icons */
.new-product-icons-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.new-product-card:hover .new-product-icons-overlay {
  opacity: 1;
}

.icon-button {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.icon-button svg {
  font-size: 1.4rem;
  color: #32cd32;
  transition: all 0.3s ease;
}

.icon-button:hover {
  background-color: #32cd32;
}

.icon-button:hover svg {
  color: white;
}

/* Hot deal variations */
.icon-button.hot-deal svg {
  color: #ff5050;
}

.icon-button.hot-deal:hover {
  background-color: #ff5050;
}

.icon-button.hot-deal:hover svg {
  color: white;
}

/* Cart icon transitions */
.new-product-cart-icon:hover {
  transform: scale(1.1);
}

.new-product-view-icon {
  font-size: 1.2rem;
  color: #32cd32;
}

@media (max-width: 768px) {
  .new-products-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    padding: 8px 0;
  }

  .new-product-image {
    height: 180px;
  }

  .new-product-name {
    font-size: 0.9rem;
  }

  .new-product-brand {
    font-size: 0.9rem;
  }

  .new-product-price {
    font-size: 0.95rem;
  }

  .new-product-old-price {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .new-products-grid {
    display: flex;
    overflow-x: auto;
    grid-template-columns: unset;
    gap: 10px;
    padding: 5px 0;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .new-products-grid::-webkit-scrollbar {
    display: none;
  }

  .new-product-card {
    flex: 0 0 160px;
    width: 160px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .new-product-card:last-child {
    margin-right: 0;
  }

  .new-product-image {
    height: 160px;
  }

  .new-product-details {
    padding: 8px 6px;
  }

  .new-product-name {
    font-size: 0.8rem;
    min-height: 18px;
  }

  .new-product-brand {
    font-size: 12px;
    min-height: 28px;
  }

  .new-product-price {
    font-size: 0.9rem;
  }

  .new-product-old-price {
    font-size: 0.7rem;
  }

  .new-product-cart-icon {
    font-size: 1.1rem;
    right: 12px;
  }

  .new-product-wishlist-icon {
    font-size: 1.4rem;
  }
}

