.cart-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cart-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.cart-modal-title {
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--text-dark);
}

.cart-modal-body {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.cart-modal-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.cart-modal-details {
    flex: 1;
}

.cart-modal-product-name {
    font-size: 16px;
    margin-bottom: 8px;
}

.cart-modal-quantity,
.cart-modal-price {
    color: var(--text-light);
    font-size: 14px;
}

.cart-modal-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.cart-modal-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    flex: 1;
}

.view-bag {
    background-color: var(--primary-green);
    color: white;
    border: none;
}

.continue-shopping {
    background-color: white;
    color: var(--primary-green);
    border: 1px solid var(--primary-green);
}
  