.recommendations-section{
    padding: 20px;
    margin-left: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}
  
.recommendations-title-and-chevron {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}
  
.recommendations-title {
  font-size: 22px;
  font-weight: 700;
  color: #222222;
  letter-spacing: -0.3px;
}
  
.recommendations-chevrons {
  display: flex;
  gap: 0.75rem;
}
  
.recommendations-chevrons .scroll-button {
  width: 28px;
  height: 28px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 16px;
}
  
.recommendations-chevrons .scroll-button:hover {
  border-color: #999;
  color: #333;
}
  
.recommendations-grid {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  width: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.recommendations-grid::-webkit-scrollbar {
  display: none;
}

.recommendations-grid:focus {
  outline: none;
}
  
  .recommendations-product-item {
    flex: 0 0 19%;
    width: 240px;
  }
  