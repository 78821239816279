.autocomplete-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #eee;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-top: -1px;
  padding-top: 24px;
}

.suggestion-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #333;
}

.suggestion-item:hover {
  background-color: #f8f8f8;
  padding-left: 20px;
}

.suggestion-item.category {
  color: #93C572;
  font-weight: 500;
  background-color: #f9fbf9;
}

.suggestion-item.category:hover {
  background-color: #f0f5f0;
}

.suggestion-icon {
  font-size: 16px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suggestion-item.product .suggestion-icon {
  color: #666;
}

.suggestion-item.category .suggestion-icon {
  color: #93C572;
}

.search-input-wrapper {
  position: relative;
}
.autocomplete-container::-webkit-scrollbar {
  width: 8px;
}

.autocomplete-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 0 4px 0;
}

.autocomplete-container::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.autocomplete-container::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: #f5f5f5;
  color: #333;
} 