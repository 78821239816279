.sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #F0F4F8;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.sign-in-form {
  background: #ffffff;
  padding: 3rem;
  /* padding-right: 4rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  align-items: center;
  text-align: left;
}

.sign-in-title {
  font-size: 1.5rem;
  color: #3D3D3D;
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-align: center;
}

.sign-in-subtitle {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.input-group {
  margin-bottom: 1.2rem;
}

.input-group input {
  width: 100%;
  padding: 0.7rem;
  font-size: 0.7rem;
  border: 1px solid #ddd;
  background-color: #F9F9F9;
  border-radius: 0 !important;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}
input:focus {
  outline: 2px solid #93C572 !important;
  border-color: #93C572 !important;
}
.input-group input::placeholder {
  font-size: 0.8rem; 
  color: #999; 
}

.input-group input:focus {
  border-color: #93C572;
  background-color: #ffffff;
  box-shadow: none;
}
.options-group {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  margin-bottom: 1.2rem;
  color: #666;
}

.checkbox-group input {
  margin-right: 0.3rem;
  accent-color: #93C572;
}

.sign-in-button {
  background-color: #93C572;
  color: #fff;
  border: none;
  padding: 0.7rem;
  width: 100%;
  font-size: 0.9rem;
  border-radius: 0 !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-in-button:hover {
  background-color: #7EAF61;
}

.additional-options {
  margin-top: 1rem;
  font-size: 0.85rem;
  text-align: center;
}

.forgot-password {
  color: #93C572;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.google-sign-up {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 0.7rem;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 1rem;
}

.google-sign-up:hover {
  background-color: #f2f2f2;
}

.google-icon {
  height: 18px;
  margin-right: 0.6rem;
}
