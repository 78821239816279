/* Checkout Page Container */
.checkoutPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9fcfb; 
    border-radius: 8px;
}
.placeorder-submitButton{
        background: #3D3D3D;
        color: white;
        padding: 12px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        width: 100%;
        margin-top: 20px;
}
.placeorder-submitButton :hover{
    background-color: #0c0c0c;
}
/* Form Section */
.formSectionContainer {
    flex: 3;
    margin-right: 40px;
}

.formSection {
    background-color: #ffffff;
    padding: 24px 30px;
    border-radius: 8px;
    margin-bottom: 20px;
}
/* Section Headers */
.checkout-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #0c0c0c;
    border-bottom: 2px solid #93C572;
    padding-bottom: 8px;
}

.checkout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.checkout-section-toggle {
    font-size: 20px;
    color: #93C572;
    cursor: pointer;
}

.checkout-section-header:hover .checkout-section-toggle {
    color: #74A45E;
}

/* Form Fields */
.shippingInfoForm, .contactInfoForm {
    display: flex;
    flex-direction: column;
    gap: 12px; 
    width: 100%;
}

.checkoutLabel {
    font-size: 14px;
    font-weight: bold;
    color: #3D1E1E;
}

.checkoutInput {
    padding: 12px;
    border: 1px solid #ddd;
    font-size: 14px;
    width: 100%; 
    background-color: #fff;
    margin-bottom: 16px;
    margin-right: 10px;
}

.checkoutInput:focus {
    border-color: #93C572;
    outline: none;
    box-shadow: 0 0 4px rgba(147, 197, 114, 0.5);
}

.checkoutInput::placeholder {
    color: #999;
    opacity: 0.8;
}

.checkoutInput-option {
    font-size: 14px;
    padding: 0px;
}

.checkoutInput-option:hover {
    background-color: #93C572;
    color: white;
}

/* Buttons */
.checkoutButtonPrimary {
    background-color: white;
    padding: 13px 0;
    border: 2px solid #93C572;
    /* border-radius: 4px; */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
}

.checkoutButtonPrimary:hover {
    background-color: #74A45E; 
}

.checkoutButtonPrimary:disabled {
    background-color: #ccc; 
    cursor: not-allowed; 
}
/* Order Summary Section */
.orderSummarySection {
    flex: 1.5;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    min-width: 350px;
}

.orderSummarySection h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #0c0c0c;
}

.summary-detail, .summary-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
}

.summary-total strong {
    font-size: 16px;
    font-weight: bold;
}

.apply-discount {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.apply-discount input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.apply-discount button {
    background-color: #93C572;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.apply-discount button:hover {
    background-color: #74A45E;
}
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .form-group {
    flex: 1;
    margin-bottom: 15px;
  }
  
  .checkbox-group {
    margin: 15px 0;
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .checkbox-label input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
  
  .address-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .checkout-btn-save {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-weight: 500;
  }

  .checkout-btn-delete {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;

    cursor: pointer;
    font-weight: 500;
  }  
  .checkout-btn-save:hover, .checkout-btn-delete:hover {
    opacity: 0.9;
  }
  
/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .checkoutPageContainer {
        flex-direction: column;
        padding: 20px;
    }

    .formSectionContainer {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .orderSummarySection {
        width: 100%;
        min-width: auto;
    }
    .form-row {
        flex-direction: column;
        gap: 0;
      }
}

