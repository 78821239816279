.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #fff;
  transition: left 0.3s ease;
  z-index: 1000;
  display: flex;
  padding-bottom: 60px; 
}

.mobile-sidebar.visible {
  left: 0;
}

.categories-nav {
  width: 90px;
  background: #d3e7c6;
  height: 100vh;
  padding: 75px 0 0 0;
  border-right: 1px solid #eee;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
  
}

.category-item {
  text-align: center;
  padding: 10px 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  transition: all 0.3s ease;
  border-radius: 50px 0 0 50px;
  margin: 0 4px;
  font-weight: 600;
}

.category-item img {
  width: 24px;
  height: 24px;
}

.category-item span:first-child {
  font-size: 24px;
  transition: transform 0.2s ease;
}

.category-item span:last-child {
  font-size: 13px;
  color: #444;
  font-weight: 700;
}

.category-item:hover {
  background: #ffffff;
  transform: none;
}

.category-item:hover span:first-child {
  transform: scale(1.1);
}

.category-item::after {
  display: none;
}

.category-item.active {
  background: #ffffff;
}

.subcategories-panel {
  flex: 1;
  padding: 10px;
  background: #fff;
}

.subcategories-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.back-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  margin-right: 16px;
}

.subcategories-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px 16px;
}

.subcategory-item {
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 8px 16px;
  border-radius: 9999px;
  background: transparent;
  border: 1px solid #93C572;
  transition: all 0.2s ease;
  min-height: 32px;
  width: fit-content;
  margin-right: 8px;
}

.subcategory-item:last-child {
  margin-right: 0;
}

.subcategory-item img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.subcategory-item span {
  font-size: 13px;
  color: #333;
  font-weight: 400;
  white-space: nowrap;
  padding: 0;
}

.sidebar-header {
  padding: 16px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-menu {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.user-section {
  display: flex;
  gap: 16px;
}

.user-section a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
}

.search-section {
  padding: 16px;
}

.search-section input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.nav-items {
  padding: 16px;
}

.featured-links {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 24px;
  padding: 0 8px;
}

.featured-links a {
  background: none;
  border: none;
  padding: 8px 16px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.featured-links a:hover {
  background: #f5f5f5;
  border-radius: 4px;
}

.categories-section h3 {
  margin-bottom: 16px;
  font-size: 16px;
  color: #333;
}

.category-button {
  width: 100%;
  text-align: left;
  padding: 12px;
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  cursor: pointer;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sidebar-footer a {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1001;
}

.close-button button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}
.category-title {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-left: 16px;
}


.bottom-nav-links {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background: #fff;
  border-top: 1px solid #eee;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
  padding: 8px 0;
  border-radius: 8px 8px 0 0;
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 4px;
  gap: 4px;
  position: relative;
}

.bottom-nav-icon {
  color: #93C572;
  font-size: 20px;
}

.bottom-nav-item span {
  font-size: 12px;
  color: #333;
  text-align: center;
}

.bottom-nav-item img {
  width: 24px;
  height: 24px;
}

.bottom-nav-item:hover {
  background: #f5f5f5;
}

.mobile-sidebar {
  padding-bottom: 80px;
}

.subcategories-panel h3 {
  font-size: 16px;
  color: #333;
  margin: 24px 0 8px 16px;
  font-weight: 500;
}
.bottom-nav-item.active {
  color: #93C572;
  font-weight: 600;
}

.bottom-nav-item.active .bottom-nav-icon {
  color: #93C572;
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.bottom-nav-item.active span:last-child {
  color: #93C572;
  font-weight: 600;
}

.bottom-nav-item.active::after {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 3px;
  background-color: #93C572;
  border-radius: 2px;
}

@media screen and (min-width: 768px) {
  .bottom-nav-links {
    display: none !important;
  }
}

.bottom-nav-links.hidden {
  display: none;
}