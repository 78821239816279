/* featured brands */
.featured-brands {
  background-color: #F0DDE5;
  padding: 20px 0;
  text-align: center;
  width: 100%; 
  overflow-x: auto; 
}

.title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  margin-bottom: 40px;
  color: #333;
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.brand-item {
  text-align: center;
}

.brand-image {
  width: 100%;
  height: auto;
  aspect-ratio: 4/5;
  object-fit: cover;
}

.brand-name {
  margin-top: 10px;
  font-weight: 600;
  font-size: 1rem;
}

/* For screens 768px and below */
@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .brand-name {
    font-size: 0.9rem;
  }
}

/* For screens 480px and below */
@media (max-width: 480px) {
  .featured-brands {
    position: relative;
    padding-top: 10px;
  }

  .title,
  .description {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #F0DDE5;
    z-index: 10;
    margin: 0;
    padding: 10px 15px;
  }

  .title {
    font-size: 1.2rem;
    padding-top: 15px;
  }

  .description {
    font-size: 0.8rem;
    padding-bottom: 15px;
  }

  .brands-grid {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding: 0 15px 20px;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }

  .brand-item {
    min-width: 200px;
    scroll-snap-align: start;
  }

  .brand-image {
    aspect-ratio: 1/1;
  }

  .brand-name {
    font-size: 0.8rem;
    margin-top: 8px;
  }
}

