/* Header container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}

.full-width-section {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none !important;
  background-color: transparent !important;
}
.logo-container {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.logo-text {
  font-size: 45px;
}
.logo-container span{
  font-weight: 600;
}
.green{
  color:#93C572;

}
.black {
  color: #000;
}
.pink{
  /* color:#B76E79; */
  color:#E17D9A;
}
.rose{
  /* color:#D4BBA6 */
  color:#EECDB7;
}
.brown{
  color:#3D1E1E
}
.black{
  color: #000000ba;
}
.gray {
  color: gray;
}

.korean-products {
  font-size: 12px;
  font-weight: lighter;
  color: #000000ba;
}

/* Search bar */
.search-bar {
  flex: 1;
  max-width: 500px;
  margin-left: 2rem;
}
.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  padding-right: 2.5rem;
  border-radius: 4px;
}

.search-input:focus {
  outline: none;
  border-color: #93C572;
}

.search-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: gray;
}
/* Icons section */
.icons-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}
.icon{
  color: #93C572;
}

.icon-item {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon-item span {
  color: black;
  font-size: 13px;
}
/* Navigation styling */
nav {
  background-color: white;
  max-width: 1200px;
  margin: 5px auto;
  position: relative;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  position: relative;
}

.nav-items {
  display: flex;
  gap: 20px;
  
}
.nav-items button {
  background: none;
  border: none;
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
  font-size: 16px;
  opacity: 1;
  cursor: pointer;
}
.nav-items button:first-letter{
  text-transform: capitalize;
}
.nav-items button:hover {
  opacity: 1;
  background-color: transparent !important;
}
.nav-items button::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #93C572;
  width: 0%;
  bottom: -5px;
}

.nav-items button:hover::before {
  width: 100%;
}
/* dropdown */
.dropdown-content {
visibility: hidden;
opacity: 0;
position: absolute;
left: 50%;
right: 50%;
margin-left: -50vw;
margin-right: -50vw;
width: 100vw;
background: white;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
z-index: 1000;
transform: translateY(-8px);
transition: 
  opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
  visibility 0.25s cubic-bezier(0.4, 0, 0.2, 1),
  transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
pointer-events: none;
}
.dropdown-inner {
max-width: 1200px;
margin: 0 auto;
padding: 25px 27px;
display: flex;
}
/*  */

.nav-item:hover .dropdown-content {
visibility: visible;
opacity: 1;
transform: translateY(5px);
pointer-events: auto;
}

/*  */

.dropdown-content a {
color: black;
padding: 0px;
text-decoration: none;
text-align: left;
display: block;
font-size: 14px;
text-transform: capitalize;
font-weight: bold;
position: relative;
}

.dropdown-content a:hover {
background-color: transparent;
}

.dropdown-content a::after {
content: '';
position: absolute;
width: 0;
height: 2px;
bottom: 0;
left: 0;
background-color: #93C572;
transition: width 0.3s ease;
}

.dropdown-content a:hover::after {
width: 100%;
}
/* Parent categories section */
.parent-categories {
  min-width: 180px;
  border-right: 1px solid #eee;
  padding: 0 25px 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}


/* Parent category button */
.parent-category {
  width: fit-content;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  padding: 6px 0;
  margin-bottom: 8px;
  font-weight: 600;
  position: relative;
}

.parent-category::before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #93C572;
  transition: width 300ms ease;
}

.parent-category:hover::before {
  width: 100%;
}

/* Child links container */
.child-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* styles for all category links */
.child-link,
.grandchild-item {
  display: block;
  width: fit-content;
  font-size: 13px;
  padding: 6px 0;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  text-align: left;
}

.child-link {
  color: #000;
  font-weight: 500;
}

.grandchild-item {
  font-weight: 400 !important;
}
.child-link::before,
.grandchild-item::before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 0;
  height: 4px !important;
  background-color: #93C572;
  transition: width 300ms ease;

}

.child-link:hover::before,
.grandchild-item:hover::before {
  width: 100%;
}

.grandchild-section {
display: flex;
gap: 40px;
padding: 0 0 20px 40px;
flex: 1;
}

.grandchild-column {
min-width: 180px;
}

/* Grandchild title */
.grandchild-title {
  width: fit-content;
  font-size: 14px;
  color: #000;
  padding: 6px 0;
  margin-bottom: 8px;
  font-weight: 500;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
}

.grandchild-title::before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #93C572;
  transition: width 300ms ease;
}

.grandchild-title:hover::before {
  width: 100%;
}

.grandchild-items {
display: flex;
flex-direction: column;
gap: 6px;
}

/* Hover states */
.nav-item:hover .dropdown-content {
visibility: visible;
opacity: 1;
}
/* new */
.menu-icon{
display: block;
}
.close-menu {
display: none;
font-size: 1.75rem;
background: none;
border: none;
color: #93C572;
padding: 0;
cursor: pointer;
position: absolute;
top: 1rem;
right: 1rem;
}
.cart-icon-container {
  position: relative;
  display: inline-flex;
}

.header-cart-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff3f3a;
  color: white !important;
  font-size: 10px !important;
  min-width: 15px;
  height: 15px;
  font-weight: bold !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}
.mobile-search-trigger,
.mobile-search-overlay {
  display: none;
}

.mobile-search-trigger {
  background: none ;
  border: none ;
  padding: 0 ;
  color: #93C572;
}


.mobile-search-trigger .icon {
  font-size: 20px;
}


@media (min-width: 769px) {
.menu-icon {
  display: none !important;
}
}
@media (max-width: 1025px) {
.container {
  padding: 1rem;
}

.icons-container {
  gap: 10px;
}

.nav-container {
  padding: 0.5rem;
  flex-direction: row;
}

.nav-items {
  flex-direction: row;
  gap: 10px;
  align-items: self-start;
}

.dropdown-content {
  padding: 0.5rem;
  display: flex;
  flex-direction: row-reverse;
}
}

/* For small screens (mobile) */
@media (max-width: 768px) {
/* Header layout */
.container {
  padding: 0.75rem 1rem;
  gap: 0.5rem;
}

.header-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-icon {
  display: block;
  background: none;
  border: none;
  font-size: 20px;
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  color: #93C572;
  transition: transform 0.3s ease;
}
nav.visible .close-menu {
  display: block;
}

.menu-icon.open {
  transform: rotate(45deg);
}
nav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  height: 100vh;
  background: white;
  z-index: 1001;
  padding: 2rem 1rem 1rem;
  width: 50%;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin: 0;
}

nav.visible {
  right: 0;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay.visible {
  display: block;
}

.nav-items {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}
.categories-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}
.category-item {
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  border: none;
  background: none;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.category-item::after {
  content: '›';
  color: #999;
  font-size: 16px;
  margin-left: 15px;
}

.category-item:hover {
  background-color: #f8f8f8;
}


.nav-items button {
  width: 100%;
  padding: 15px 20px;
  text-align: left;
  border: none;
  background: none;
  font-size: 14px;
  color: black;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items button:hover {
  background-color: #f8f8f8;
}

.logo-container {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.logo {
  margin-right: 0;
}

.logo-text {
  font-size: 32px;
  line-height: 1;
}

.korean-products {
  font-size: 10px;
  margin-top: -2px;
}
.menu-toggle {
  display: block;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  width: 70%;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 999;
  padding: 60px 20px 20px;
  overflow-y: auto;
}

.mobile-nav.visible {
  display: block;
  right: 0;
}

.nav-container {
  padding: 0;
}

.nav-items {
  flex-direction: column;
  align-items: flex-start;
}

.nav-items button {
  width: 100%;
  text-align: left;
  padding: 10px 0;
}

.dropdown-content {
  position: static;
  visibility: visible;
  opacity: 1;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.dropdown-inner {
  padding: 10px 0px;
}

.dropdown-subcategories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 20px;
  width: 100%;
}
.dropdown-subcategories::after{
  content: '›';
  color: #999;
  font-size: 20px;
  margin-left: 15px;
 
}

.subcategory:hover {
  color: #93C572;
}

/* Active category state */
.category-item.active {
  color: #93C572;
  background: #f8f8f8;
}

.category-item.active::after {
  color: #93C572;
  transform: rotate(90deg);
}

.mobile-nav.visible::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.header-top {
  position: relative;
  z-index: 1000;
  background: white;
}
.icons-container {
  margin-left: 20px; 
  gap: 20px;
}
.icon-item  span{
  display: none;
}
.icon-item{
  display: flex;
}

.no-categories {
  text-align: center;
  color: #999;
  font-size: 13px;
  font-style: italic;
}
}

@media (max-width: 480px) {
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: white;
  position: relative;
}
.logo-container {
  text-decoration: none;
  flex: 1;
}

.logo-text {
  font-size: 1.75rem;
  font-weight: bold;
}

.korean-products {
  display: none;
}

/* Cart Icon */
.icon-item {
  padding: 0;
  margin-right: 1.5rem;
}

.icon-item span {
  display: none;
}

.icon {
  font-size: 1.2rem;
  color: #93C572;
}

.icons-container {
  gap: 8px;
}

.icon-item:nth-child(4) {  
  display: none;
}
/* search */
.search-bar {
  position: fixed;
  width: 100%;
  margin: 0 0 1rem 0;
  display:none;
}

nav.visible .search-bar{
  display: block;
  margin-top: -2rem;
}

.search-button {
  background: none;
  border: none;
  padding: 0;
}

.search-icon {
  font-size: 1.2rem;
  color: #93C572;
}
.menu-icon {
  display: block;
  font-size: 24px;
  background: none;
  border: none;
  color: #93C572;
  padding: 0;
  cursor: pointer;
  margin-left: auto;
}

.search-input-wrapper {
  display: block;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
}
.search-input-wrapper.active {
  position: static;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}


.search-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.mobile-search-overlay .search-input-wrapper .search-input:focus {
  outline: none;
  border :none;
}

main {
  margin-top: 4rem;
}

nav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 280px;
  height: 100vh;
  background: white;
  z-index: 1002;
  padding: 1.5rem 1rem 1rem 0.5rem;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

nav.visible {
  right: 0;
}

nav.visible .close-menu {
  display: block;
}
.nav-container {
  margin-top: 1rem;
}

.nav-items {
  display: flex;
  flex-direction: column;
}

.nav-items a {
  color: #333;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 0.5rem 0;
}

.close-menu {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #93C572;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.category-item,
.nav-items button {
  padding: 13px 20px;
  font-size: 13px;
}

.dropdown-inner {
  padding: 8px 20px;
}

.subcategory {
  font-size: 12px;
  padding: 6px 0;
}
  .desktop-search {
    display: none;
  }

  .mobile-search-trigger {
    display: flex !important;
  }

  .mobile-search-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .mobile-search-overlay form {
    background: white;
    padding: 15px;
    position: relative;
    z-index: 1001;
  }

  .mobile-search-overlay .search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #93C572;
    border-radius: 25px;
    padding: 8px 15px;
  }

  .mobile-search-overlay .search-input {
    width: 100%;
    padding: 8px 35px 8px 5px;
    border: none !important;
    background: transparent;
    font-size: 14px;
    border-radius: 0;
  }

  /* Reset all input styles */
  .mobile-search-overlay input.search-input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none !important;
    margin: 0 !important;
    padding: 8px 35px 8px 5px !important;
  }

  /* Reset focus styles */
  .mobile-search-overlay input.search-input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .mobile-search-overlay .close-search {
    position: absolute;
    right: 15px;
    background: none;
    border: none;
    padding: 8px;
    color: #666;
    font-size: 16px;
    cursor: pointer;
  }

  /* Reset autocomplete styles */
  .mobile-search-overlay .autocomplete {
    background: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 1px solid #eee;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
}

