.slider {
    position: relative;
    height: 600px; 
    overflow: hidden;
    text-align: center;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.8s ease;
    
}

.slide.active {
    transform: translateX(0);
    z-index: 2;
}

.slide.prev {
    transform: translateX(-100%);
    z-index: 1;
}
  
.slider-content {
    background-color: rgba(0, 0, 0, 0.005); 
    width: 100%;
    height: 100%; 
    color: #000;
    text-align: left;
    position: absolute;
    top: 0; 
    left: 0; 
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 15%;
    padding-bottom: 120px;
}
.slider-tag {
    font-size: 0.8rem;
    text-transform: capitalize;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
    padding-bottom: 6px;
    font-weight: 500;
    letter-spacing: 1px;
}

.slider-tag::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 1px;
    background-color: #141414;
}

.slider-content h2 {
    font-size: 2.8rem;
    margin: 0;
    font-weight: 700;
    line-height: 1.2;
    color: #141414;
    white-space: pre-line;
    text-transform: capitalize;
    letter-spacing: -1px;
    margin-bottom: 20px;
}
  
.slider-content p {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: 500;
    opacity: 0.8;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    line-height: 1.5;
}
  
.shop-now-btn {
    align-items: center;
    display: inline-flex;
    height: 44px;
    justify-content: center;
    min-width: 158px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    color: #141414;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transition: all 0.3s ease;
}
  
.shop-now-btn:hover {
    color: white;
    background-color: #93C572;
}

.slider-controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background: rgba(41, 40, 40, 0.251);
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 500;
    gap: 8px;
    z-index: 3;
}
.arrow-btn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
    line-height: 1;
}
  
.slide-counter {
    color: white;
    font-size: 14px;
    margin: 0 5px;
}
  
.play-pause-btn {
    background: none;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    padding: 0 5px;
    margin-top: 3px;
    margin-left: 5px;
}
  
.arrow-btn:hover,
.play-pause-btn:hover {
    opacity: 0.8;
}

.slide.active .slider-tag {
    opacity: 0;
    transform: translateX(50px);
    animation: fadeInLeft 0.6s ease forwards;
}

.slide.active h2 {
    opacity: 0;
    transform: translateX(50px);
    animation: fadeInLeft 0.6s ease forwards 0.2s;
}

.slide.active p {
    opacity: 0;
    transform: translateX(50px);
    animation: fadeInLeft 0.6s ease forwards 0.4s;
}

.slide.active .shop-now-btn {
    opacity: 0;
    transform: translateX(50px);
    animation: fadeInLeft 0.6s ease forwards 0.6s;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


@media (min-width: 1441px) {
    .slider-content {
        padding-left: 20%;
    }
}

@media (max-width: 1024px) {
    .slider-content {
        padding: 150px 5%; 
    }

    .slider-content h2 {
        font-size: 2.4rem;
    }

    .slider-content p {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .slider {
        height: 500px; 
    }

    .slider-content {
        padding: 120px 5%;
        padding-bottom: 60px;
    }

    .slider-content h2 {
        font-size: 2.5rem;
        line-height: 1.2;
    }

    .slider-tag {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .slider {
        height: 450px;
    }

    .slider-content {
        padding: 100px 5%;
        padding-bottom: 80px;
    }

    .slider-content h2 {
        font-size: 2rem;
        line-height: 1.2;
    }

    .slider-content p {
        font-size: 0.85rem;
        margin: 8px 0 15px 0;
    }

    .slider-tag {
        font-size: 0.75rem;
        margin-bottom: 10px;
    }

    .shop-now-btn {
        height: 40px;
        min-width: 140px;
        font-size: 0.8rem;
    }

    .slider-controls {
        padding: 3px 8px;
    }

    .slide-counter {
        font-size: 12px;
    }
}