.products{
    margin: 10px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
  }

  .list-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .list-header-title {
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    padding: 30px 20px; 
    position: relative; 
  }
  .list-header h2 {
    font-size: 1.5rem;
    color:  #444;
    font-weight: 400;
    margin: 0;
  }
  .list-header-title::after{
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 100px; 
    height: 80px; 
    background: url('../assets/images/cosmetics.jpeg') no-repeat center / contain;
    opacity: 0.8; 
  }
  .sort-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0 10px;
  }
  .sort-options span {
    margin: 0 5px;
    font-size: 0.9rem;
    color: #555;
  }
  
  .sort-options select {
    margin-left: 10px;
    padding: 8px 15px;
    font-size: 0.9rem;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    min-width: 120px;
    color: #333;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
  }
  
  .sort-options select:hover {
    border-color: #93C572;
  }
  
  .sort-options select:focus {
    outline: none;
    border-color: #93C572;
    box-shadow: 0 0 0 2px rgba(147, 197, 114, 0.1);
  }
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .products-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  @media (max-width: 768px) {
    .products{
      display: flex;
      flex-direction: column;
      max-width: 100%;  }
    .list-header-title {
      padding: 20px;
    }

    .list-header h2 {
      font-size: 1.2rem;
    }

    .sort-options {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .sort-options select {
      margin-left: 0;
      width: 100%;
    }

    .products-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }
  @media (max-width: 480px) {
    .products {
      margin: 10px;
      padding: 10px;
    }

    .products-grid {
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 10px;
      margin: 0;
    }
  }
