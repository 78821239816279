.contact-us-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 40px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .contact-info{
    width: 40%;
  }
  .contact-form {
    width: 60%;

  }
  .contact-info, .contact-form {
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .contact-info h2, .contact-info h3 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #3e3c3c;
  }
  
  .contact-info p {
    margin: 10px 0;
    font-size: 16px;
    color: #383737;
  }
  
  .social-links {
    list-style-type:none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .social-links li {
    margin: 5px 0;
  }
  
  .social-links a {
    text-decoration: none;
    color: #383737;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #28a745;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #3e3c3c;
  }
  
  .contact-form-group {
    margin-bottom: 20px;
  }
  
  .contact-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #383737;
  }
  
  .contact-form-group input,
  .contact-form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .contact-form-group input:focus,
  .contact-form-group textarea:focus {
    border-color: #28a745;
  }
  
  .contact-form-group textarea {
    height: 120px;
    resize: none;
  }
  
  .contact-submit-btn {
    padding: 15px 30px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    align-self: flex-end;
  }
  
  .contact-submit-btn:hover {
    background-color: #218838;
  }
  
  /* Responsive for smaller screens */
  @media (max-width: 768px) {
    .contact-us-container {
      flex-direction: column;
    }
  
    .contact-info,
    .contact-form {
      width: 100%;
    }
  }
  