/* Cart Container */
.cart-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  width: 100%;
}

.cart-items-section {
  flex: 3;
}

.cart-title {
  font-size: 24px;
  font-weight: 600;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 20px;
}

.cart-items-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Sidebar */
.cart-sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}

/* Empty Cart Styles */
.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: 40px 20px;
  text-align: center;
}

.empty-cart-image {
  width: 200px;
  height: auto;
  margin-bottom: 32px;
  opacity: 0.8;
  animation: float 3s ease-in-out infinite;
}

.empty-cart-title {
  color: #333;
  font-size: 20px;
  margin-bottom: 6px;
}

.empty-cart-text {
  color: #666;
  margin-bottom: 32px;
}

.continue-shopping-btn {
  background-color: #93C572;
  color: white;
  border: none;
  padding: 14px 20px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.continue-shopping-btn:hover {
  background-color: #7baf5a;
  transform: translateY(-2px);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cart-warning {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.empty-cart p {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.cart-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.btn-signin,
.btn-continue {
  background-color:#93C572 ;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-signin:hover,
.btn-continue:hover {
  background-color: #4b7e29; 
}

.btn-signin {
  background-color: #93C572; 
}

.btn-signin:hover {
  background-color: #4b7e29;
}

/* Responsive styles */
@media (max-width: 768px) {
  .progress-indicator {
    margin: 20px auto; /* Further reduced for mobile */
  }

  .step-circle {
    width: 32px; /* Slightly smaller on mobile */
    height: 32px;
    font-size: 13px;
  }

  .step-text {
    font-size: 11px;
  }

  .progress-line {
    top: 16px;
    left: 40px;
    right: 40px;
  }

  .cart-container {
    flex-direction: column;
    padding: 20px;
  }

  .cart-items-section, .cart-sidebar {
    flex: 1;
    width: 100%;
  }

  .cart-items-section {
    margin-bottom: 30px;
  }

  .cart-title {
    text-align: center;
  }

  .empty-cart {
    min-height: 40vh;
    padding: 20px;
  }

  .empty-cart-image {
    width: 150px;
    margin-bottom: 24px;
  }

  .empty-cart-title {
    font-size: 24px;
  }

  .cart-warning {
    font-size: 20px;
  }
}
