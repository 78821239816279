.shop-categories {
  width: 100%;
  margin: 0 auto;
  background-color: #E5E7EA;
  padding: 60px 10px;
  overflow-x: auto;
}
.shop-categories-container {
  max-width: 1200px;
  margin: 0 auto;
}
.shopbycategory-title {
  font-size: 20px;
  padding: 0 1rem;
  font-weight: 700;
  color: #222222;
  margin-bottom: 4rem; 

}
.shop-categories__grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  padding: 0 1rem;
}

.shop-categories__item {
  flex: 0 0 auto;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.shop-categories__item:hover {
  transform: scale(1.1);
}

.shop-categories__image-wrapper {
  width: 180px;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shop-categories__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-categories__title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.shop-categories-container::-webkit-scrollbar {
  display: none;
}

.shop-categories-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 768px) {
  .shop-categories {
    padding: 40px 15px;
  }

  .shop-categories__grid {
    gap: 15px;
  }

  .shop-categories__image-wrapper {
    width: 80px;
    height: 100px;
  }


  .shop-categories__title {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .shop-categories {
    padding: 30px 0;
    position: relative;
  }

  .shopbycategory-title {
    font-size: 18px;
    padding: 0 15px;
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .shop-categories__grid {
    display: flex;
    overflow-x: auto;
    gap: 8px;
    padding: 0 15px;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }

  .shop-categories__item {
    width: 140px;
    scroll-snap-align: start;
  }

  .shop-categories__image-wrapper {
    width: 120px;
    height: 150px;
  }
  .shop-categories__title {
    font-size: 12px;
    margin-top: 12px;
  }
}


