/* Container for the entire layout */
.myaccount-container {
    display: flex;
    padding: 20px;
    background-color: #f7faf8;
    min-height: 100vh;
    border-radius: 10px;
}
.myaccount-wish-list h2{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}
.myaccount-contact-section h2{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}
.myaccount-track-orders h2{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}
/* Sidebar Menu */
.myaccount-sidebar {
    width: 250px;
    background-color: #fff;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.myaccount-menu-section h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
    font-weight: bold;
}

.myaccount-menu-section ul {
    list-style-type: none;
    padding: 0;
}

.myaccount-menu-section li {
    margin-bottom: 12px;
    font-size: 15px;
    color: #555;
    cursor: pointer;
    transition: color 0.3s;
}

.myaccount-menu-section li:hover {
    color: #36af3b;
    text-decoration: underline;
}

/* Main Content Area */
.myaccount-main-content {
    margin-left: 30px;
    flex: 1;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Header Section */
.myaccount-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 8px 8px 0 0;
}

.myaccount-user-info {
    display: flex;
    align-items: center;
}

.myaccount-profile-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: gray;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-size: 12px;
    font-weight: bold;
}

.myaccount-savings {
    color: #777;
    font-size: 14px;
}

/* Track Orders Section */
.myaccount-track-orders {
    margin-top: 30px;
}

.myaccount-order-status {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.myaccount-track-orders-button {
    margin-top: 15px;
    padding: 12px 25px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.myaccount-track-orders-button:hover {
    background-color: #36af3b;
}

/* Wish List Section */
.myaccount-wish-list {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    border-radius: 8px;
}

/* Contact Section */
.myaccount-contact-section {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    border-radius: 8px;
}
/* Wish List Section */
.myaccount-wish-list {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.wishlist-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.wishlist-item-image {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    margin-right: 15px;
}

.wishlist-item-details {
    flex-grow: 1;
}

.wishlist-item-title {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.wishlist-item-description {
    color: #777;
    font-size: 14px;
}

.wishlist-item-price {
    text-align: right;
    margin-right: 20px;
}

.price-current {
    color: #d9534f;
    font-weight: bold;
    font-size: 14px;
}

.price-old {
    text-decoration: line-through;
    color: #aaa;
    font-size: 14px;
}

.wishlist-item-manage {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.wishlist-add-to-bag,
.wishlist-remove {
    background-color: white;
    border: 1px solid #ddd;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 13px;
}

.wishlist-remove {
    color: #d9534f;
}

.wishlist-remove-all {
    margin-top: 15px;
    color: #d9534f;
    cursor: pointer;
    font-size: 14px;
}

.wishlist-remove-all:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .myaccount-container {
    flex-direction: column;
    padding: 10px;
  }

  .myaccount-sidebar {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: none;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .myaccount-main-content {
    margin-left: 0;
  }

  .myaccount-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .myaccount-user-info {
    margin-bottom: 15px;
  }

  .myaccount-order-status {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .myaccount-container {
    padding: 5px;
  }

  .myaccount-sidebar {
    padding: 10px;
  }

  .myaccount-menu-section h3 {
    font-size: 16px;
  }

  .myaccount-menu-section li {
    font-size: 14px;
  }

  .myaccount-main-content {
    padding: 15px;
  }

  .myaccount-header {
    padding: 10px;
  }

  .myaccount-profile-circle {
    width: 50px;
    height: 50px;
    font-size: 10px;
  }

  .myaccount-savings {
    font-size: 12px;
  }

  .myaccount-track-orders-button {
    padding: 10px 20px;
  }

  .wishlist-item {
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
  }
  .wishlist-item-manage{
    margin-top: 10px;
    gap: 10px;
  }

  .wishlist-item-price {
    text-align: left;
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .myaccount-container {
    font-size: 12px; 
  }
  .myaccount-details{
    font-size: 12px;
  }
  .myaccount-wish-list h2{
    font-size: 16px;
  }
  .myaccount-contact-section h2{
    font-size: 16px;
  }
  .myaccount-contact-section p{
    font-size: 12px;
  }
  .myaccount-track-orders h2{
    font-size: 16px;
  }
  .myaccount-track-orders-button{
    font-size: 12px;
    padding: 10px 10px;
  }
  .wishlist-item-manage{
    flex-direction: column;
  }
  .wishlist-item-image{
    width: 50px;
    height: 50px;
    margin-right: 3px;
  }
  .wishlist-item-title{
    font-size: 12px;
  }
  .wishlist-item-price{
    font-size: 8px;
  }
  .price-current{
    font-size: 10px;
  }
  .price-old{
    font-size: 8px;
  }
  .wishlist-item-manage{
    margin-top: 10px;
    gap: 10px;
  }
  .wishlist-add-to-bag,
  .wishlist-remove {
    font-size: 8px;
    padding: 3px 3px;
    border-radius: 0px;
  }
}
