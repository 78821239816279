.reviewPage {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.reviewPage__content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.reviewPage__left {
    flex: 1;
    max-width: 300px; 
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reviewPage__summary {
    text-align: center;
}

.reviewPage__summaryRating {
    font-size: 3rem;
    color: #4caf50;
}

.reviewPage__summaryText {
    color: #555;
    margin-bottom: 10px;
}

.reviewPage__writeReviewBtn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 10px;
}

.reviewPage__writeReviewBtn:hover {
    background-color: #45a049;
}

.reviewPage__ratingDistribution {
    margin-top: 30px;
}
.ratingDistribution {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ratingDistribution__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ratingDistribution__barFillContainer {
    flex-grow: 1;
    background-color: #eee;
    height: 10px;
    margin: 0 10px;
    border-radius: 5px;
}

.ratingDistribution__barFill {
    background-color: #4caf50;
    height: 100%;
    border-radius: 5px;
}
.reviewPage__right {
    flex: 2;
}

.reviewPage__reviewsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reviewCard {
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reviewCard__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.reviewCard__header h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #4caf50;
}

.reviewCard__header p {
    font-size: 0.9rem;
    color: #888;
}

.reviewCard__content {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
}

/* Review Modal Styles */
.review-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.review-modal {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 5px;
}

.close-button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.review-modal h2 {
    margin: 0 0 20px 0;
    color: #333;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.rating-container {
    margin-bottom: 20px;
}

.rating-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.stars-container {
    display: flex;
    gap: 8px;
}

.stars-container button {
    background: none;
    border: none;
    font-size: 24px;
    color: #ddd;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s;
}

.stars-container button.filled {
    color: #ffd700;
}

.stars-container button:hover {
    transform: scale(1.1);
}

.review-input-container {
    margin-bottom: 20px;
}

.review-input-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.review-input-container textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-size: 14px;
    min-height: 100px;
}

.review-input-container textarea:focus {
    outline: none;
    border-color: #4caf50;
}

.review-input-container textarea:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.2s;
}

.submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.submit-button:not(:disabled):hover {
    background-color: #45a049;
}

.reviews-section {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.reviews-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.reviews-summary {
    text-align: center;
    margin-bottom: 30px;
}

.rating-number {
    font-size: 48px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.write-review-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
}

.write-review-btn:hover {
    background-color: #0056b3;
}

.reviews-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.review-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
}

.review-header {
    margin-bottom: 10px;
}

.rating-stars {
    color: #ffd700;
    font-size: 18px;
    margin-bottom: 5px;
}

.review-meta {
    color: #666;
    font-size: 14px;
}

.review-meta p {
    margin: 0;
}

.review-content {
    margin: 0;
    line-height: 1.5;
}
