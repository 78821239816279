.account-info-container {
    flex: 1;
    padding: 25px;
    background-color: #fff;
    margin: 0 20px;
    min-height: calc(100vh - 120px);
}

.account-info-container h2 {
    font-size: 22px;
    color: #333;
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.account-sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 30px;
}

.account-section {
    background: #f8f9fa;
    padding: 20px;
    border: 1px solid #eee;
}

.account-section h3 {
    font-size: 16px;
    color: #444;
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
}

.info-grid {
    display: grid;
    gap: 15px;
}

.info-item {
    display: grid;
    grid-template-columns: 140px 1fr;
    align-items: center;
    gap: 10px;
}

.info-item label {
    font-size: 13px;
    color: #666;
    font-weight: 500;
}

.info-item input {
    font-size: 13px;
    border: 1px solid #e0e0e0;
    padding: 6px 10px;
    color: #333;
    background: #fff;
}

.info-item input:focus {
    border-color: #93C572;
    outline: none;
}

.info-item span {
    font-size: 13px;
    color: #333;
}

.status-badge {
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
}

.status-badge.verified {
    background-color: #e3f3e3;
    color: #2e7d32;
}

.updateinfo-button {
    padding: 8px 20px;
    border-radius: 4px;
    background-color: #93C572;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    float: right;
}

.updateinfo-button:hover {
    background-color: #7baf5a;
}

/* Responsive styles */

/* For screens 1024px and below */
@media (max-width: 1024px) {
    .account-sections {
        grid-template-columns: 1fr;
    }
}

/* For screens 768px and below */
@media (max-width: 768px) {
    .account-info-container {
        padding: 15px;
        margin: 0 10px;
    }

    .account-section {
        padding: 15px;
    }

    .info-item {
        grid-template-columns: 1fr;
    }

    .info-item label {
        margin-bottom: 5px;
    }
}

/* For screens 480px and below */
@media (max-width: 480px) {
    .account-info-container h2 {
        font-size: 18px;
    }

    .account-section h3 {
        font-size: 14px;
    }

    .info-item label,
    .info-item input,
    .info-item span {
        font-size: 12px;
    }

    .updateinfo-button {
        width: 100%;
        float: none;
        margin-top: 20px;
    }
}
