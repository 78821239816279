.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
  flex-direction: column;
}

.dots-loader {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #7bbb60; 
  border-radius: 50%;
  animation: bounce 1s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.loading-text {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #8c8c8c;
  font-weight: 500;
  animation: fadeIn 0.8s infinite alternate;
}

@keyframes fadeIn {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
