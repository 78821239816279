.ad-section {
    padding: 1rem 0;
    background: linear-gradient(45deg, #fff5f6,#ffe6ea );
}

.ad-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    position: relative;
}

.ad-content h2 {
    font-size: 1.1rem;
    color: #333;
    font-weight: 600;
    margin: 0;
    margin-right: 2rem;
    white-space: nowrap;
}

.benefits-list {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
}

.benefit-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.benefit-text{
    font-size: 1rem;
    color: #444;
    font-weight: 500;
    white-space: nowrap;
}
.benefit-icon {
    font-size: 1.2rem;
}

.benefit-item span:last-child {
    font-size: 0.9rem;
    color: #444;
    white-space: nowrap;
}

.benefit-divider {
    width: 1px;
    height: 20px;
    background-color: #ddd;
}

/* Sephora-style sticker */
.promo-sticker {
    background: #141414;
    color: white;
    padding: 0.8rem 1.2rem;
    border-radius: 4px;
    margin-left: 2rem;
}

.sticker-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
}

.sticker-label {
    font-size: 0.7rem;
    letter-spacing: 1px;
    opacity: 0.8;
}

.sticker-value {
    font-size: 0.9rem;
    font-weight: 600;
}

.join-now-btn {
    padding: 0.8rem 2rem;
    background: #93C572;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.join-now-btn:hover {
    background: #7fb15f;
}

@media (max-width: 1024px) {
    .ad-content {
        padding: 0 20px;
    }

    .ad-content h2 {
        font-size: 14px;
    }

    .benefit-text {
        font-size: 13px;
    }

    .benefit-icon {
        font-size: 15px;
    }

    .promo-sticker {
        padding: 8px 16px;
    }

    .sticker-label {
        font-size: 10px;
    }

    .sticker-value {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .ad-content {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }

    .ad-content h2 {
        margin-right: 0;
    }

    .benefits-list {
        justify-content: center;
    }

    .promo-sticker {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}

@media (max-width: 480px) {
    .ad-section {
        padding: 4px 0;
    }

    .ad-content {
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
    }

    .ad-content h2 {
        font-size: 12px;
        margin-right: 15px;
    }

    .benefits-list {
        order: 1;
        gap: 10px;
        flex: none;
        width: auto;
    }

    .benefit-text {
        font-size: 11px;
    }

    .benefit-icon {
        font-size: 13px;
    }

    .promo-sticker {
        order: 2;
        margin-left: 0;
        margin-top: 2px;
        padding: 4px 14px;
    }

    .sticker-label {
        font-size: 9px;
    }

    .sticker-value {
        font-size: 11px;
    }

    .benefit-divider {
        height: 14px;
    }
}