.top-banner {
  background: #fff5f6;
  color: #333;
  height: 32px;
  width: 100%;
  position: relative;
}

.banner-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.banner-content {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.banner-slide {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
}

.announcement {
  min-width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.announcement-text {
  font-weight: 700;
  white-space: nowrap;
  color: #222;
  text-transform: capitalize;
  letter-spacing: 0.8px;
}

.banner-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0;
  z-index: 2;
}

.banner-nav.prev {
  left: 15px;
}

.banner-nav.next {
  right: 15px;
}

.banner-nav:hover {
  color: #333;
}


@media (max-width: 768px) {
  .top-banner {
    height: 28px;
  }

  .announcement {
    height: 28px;
    font-size: 12px;
    padding: 0 24px;
  }
  
  .banner-nav {
    padding: 0 8px;
  }
} 
@media (max-width: 480px) {
.banner-nav{
  display: none;
}
}
