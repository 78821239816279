/* footer styles */

.footer-container{
    padding: 0;
    background-color: #E4E3E2; 
    color: #1f1f1f;
    align-items: center;
}

.footer {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 1.5rem;
    gap: 1rem;
}
.footer div {
    flex: 1;
}
.footer h3 {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 700;
}
.footer p {
    margin-bottom: 0.3rem;
    font-size: 0.8rem;
    line-height: 1.2;
}
.business-info {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.5rem 1.5rem;
    border-top: 1px solid #ccc;
}
.company-details {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0.5rem 0;
    gap: 1rem;
}
.company-details img {
    width: 100px;
    height: auto;
}
.company-details h3 {
    font-size: 0.85rem;
    margin: 0;
}
.company-info {
    flex: 2;
    line-height: 1;   
    padding: 0 2.5rem;
}
.company-info p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
}
.payment-delivery {
    text-align: left;
    flex: 1;
}
.payment-delivery h4 {
    font-size: 0.9rem;
    margin: 8px 0;
}
.payment-delivery img {
    height: 20px;
    margin-right: 0.5rem;
    width: auto;
    object-fit: contain;
}
.copyright {
    text-align: center;
    padding: 0.7rem;
    max-width: 1200px;
    margin: 0 auto;
    border-top: 1px solid #ccc;
    font-size: 0.8rem;
    color: #666;
}
.info-link {
    color: #666;
    text-decoration: underline;
    font-size: 0.9rem;
}

.chevron-icon {
    display: none;
  }
  
.social-media {
  display: flex;
  gap: 1rem;
}

.social-media a {
  color: #666;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #32cd32; 
}

.delivery-method {
  margin-top: 10px;
}

/* Mobile responsive */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 2rem 1.5rem;
    }

    .business-info {
        flex-direction: column;
        gap: 1.5rem;
    }

    .payment-delivery {
        text-align: left;
    }
    .company-info {
        padding: 0;
    }
}

@media (max-width: 480px) {
  .footer {
    padding: 0;
  }

  .footer-section {
    border-bottom: 1px solid #ddd;
  }

  .footer h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0;
    cursor: pointer;
    user-select: none;
  }

  .chevron-icon {
    display: block;
    transition: transform 0.3s ease;
  }

  .footer-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding: 0 1rem;
  }

  .footer-section.active .chevron-icon {
    transform: rotate(180deg);
  }
  .footer-section.active .footer-content {
    max-height: 300px; 
  }

  .footer-content p {
    margin: 0.75rem 0;
  }

  /* Keep existing mobile styles */
  .business-info {
    padding: 1rem;
  }
  .company-info {
    padding: 0;
  }
  .company-details {
    padding: 0;
    margin: 0;
  }
}


@media (max-width: 480px) {
  .social-media {
    gap: 0.8rem;
    margin-bottom: 1rem;
  }
  
  .social-media a {
    font-size: 1rem;
  }
}

