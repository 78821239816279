.order-summary {
    background: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .order-summary h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .summary-detail,
  .summary-total {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .summary-total strong {
    font-size: 14px;
  }
  
  .checkout-button {
    background: #3D3D3D;
    color: white;
    padding: 12px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
  }
  
  .checkout-button:hover {
    background: #171717;
  }
  .promos-section {
    background: #fff;
    padding: 20px;
    border: 1px solid #ddd;
  }
  
  .promos-section h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .promo-input {
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  .apply-promo-button {
    background: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .apply-promo-button:hover {
    background-color: #45a049;
  }
  