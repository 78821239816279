.progress-indicator {
  max-width: 600px;
  margin: 30px auto;
  position: relative;
  padding: 0 10px;
}

.progress-line {
  position: absolute;
  top: 18px;
  left: 50px;
  right: 50px;
  height: 1px;
  background: #e0e0e0;
  z-index: 1;
}

.progress-line-fill {
  height: 100%;
  width: 0%;
  background: #93C572;
  transition: width 0.3s ease;
}

.progress-steps:has(.step-item:nth-child(1).active) ~ .progress-line .progress-line-fill {
  width: 0%;
}

.progress-steps:has(.step-item:nth-child(2).active) ~ .progress-line .progress-line-fill {
  width: 50%;
}

.progress-steps:has(.step-item:nth-child(3).active) ~ .progress-line .progress-line-fill {
  width: 100%;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.step-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: white;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: #999;
  margin-bottom: 6px;
  transition: all 0.3s ease;
}

.step-text {
  font-size: 13px;
  color: #999;
  font-weight: 500;
  transition: color 0.3s ease;
}
.step-item.active .step-circle {
  border-color: #93C572;
  background: #93C572;
  color: white;
}

.step-item.active .step-text {
  color: #93C572;
}

.step-item.completed .step-circle {
  background: #93C572;
  border-color: #93C572;
  color: white;
}


@media (max-width: 768px) {
  .progress-indicator {
    margin: 20px auto;
    padding: 20px;
  }

  .step-circle {
    width: 32px;
    height: 32px;
    font-size: 13px;
  }

  .step-text {
    font-size: 11px;
  }

  .progress-line {
    top: 16px;
    left: 40px;
    right: 40px;
  }
}
@media (max-width: 480px) {
  .progress-indicator {
    margin: 20px auto;
    padding: 20px;
  }
  .step-circle {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
  .step-text {
    font-size: 10px;
  }
}

