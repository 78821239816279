:root {
    --primary-green: #4caf50;
    --dark-green: #4caf50;
    --light-green: #E8F5E0;
    --text-dark: #333333;
    --text-light: #666666;
    --background-light: #FFFFFF;
    --border-color: #E0E0E0;
    --red: #f00;
    --lightred:rgba(255, 25, 0, 0.064);
  }
  
  .orders-container {
    flex: 1;
    min-width: 700px;
    padding: 20px;
    background: var(--background-light);
    font-family: 'Lato', sans-serif;
    overflow-x: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .orders-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-dark);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--primary-green);
    line-height: 1.2; 
  }
  
  .orders-list {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
  }
  
  .order-item-header, .order-item {
    display: table-row;
    background-color: var(--background-light);
  }
  
  .order-item-cell, .order-item-cell-header {
    display: table-cell;
    padding: 16px;
    font-size: 13px;
    max-width: 180px;
    border-bottom: 1px solid #f0f0f0;
    vertical-align: middle;
  }

  .order-item-cell-header {
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  
  .order-id {
    font-weight: 600;
    color: var(--primary-green);
  }
  
  .order-date, .order-total, .order-payment-status {
    font-size: 16px;
    color: var(--text-dark);
  }
  .order-status {
    font-size: 12px;
    font-weight: 500;
    color: var(--background-light);
    background-color: var(--primary-green);
    padding: 3px 12px;
    border-radius: 20px;
    width: fit-content;
    text-align: center;
  }

  .order-payment-status {
    color: #f44336;
    background-color: var(--lightred);
    border: 1px solid #f44336;
    padding: 3px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
}
  .order-details {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--border-color);
  }
  
  .order-detail-item {
    font-size: 14px;
    color: var(--text-light);
  }
  
  .order-detail-label {
    font-weight: 600;
    color: var(--text-dark);
    margin-bottom: 5px;
  }
  .order-item-cell-button{
    background-color: #FF9900;
    color:#fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.order-item-cell-button.paid {
    background-color: var(--primary-green);
}
  
  @media (max-width: 768px) {
    .order-item {
      grid-template-columns: 1fr 1fr;
    }
    
    .order-status {
      grid-column: 1 / -1;
      justify-self: start;
    }
  }
  
  @media (max-width: 480px) {
    .order-item {
      grid-template-columns: 1fr;
    }
  }
  
  .myaccount-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .myaccount-container {
        grid-template-columns: 1fr;
    }
  }
  
  .no-orders-message {
    text-align: center;
    color: var(--text-light);
    padding: 40px 0;
    font-size: 14px;
    border-top: 1px solid var(--border-color);
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .orders-container {
      min-width: 100%;
    }
  }
  