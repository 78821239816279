.category-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
}
  
  .category-content {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    width: 100%;
  }
  .category-content > *:first-child {
    width: 250px; 
    margin-right: 20px;
  }
  .category-content > *:last-child {
    flex-grow: 1;
    max-width: calc(100% - 270px);
  }
  .product-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .category-content {
      flex-direction: row;
    }

    .category-content > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .category-content {
      flex-direction: column;
    }

    .category-content > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .category-content > *:last-child {
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
    }

    .products-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
  }

  @media (max-width: 480px) {
    .category-content {
      padding: 10px;
    }

    .products-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
  