.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #93C572;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #93C572;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.scroll-to-top:hover {
  background: #93C572;
  color: white;
  transform: translateY(-3px);
}

.scroll-to-top svg {
  font-size: 20px;
}

@media (max-width: 768px) {
  .scroll-to-top {
    bottom: 80px;
    right: 20px;
    width: 35px;
    height: 35px;
  }
} 