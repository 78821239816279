/* Container Styles */
.product-view-container {
  max-width: 1200px;
  padding: 1rem;
  margin: 0 auto;
  font-family:inherit;
}

.product-view {
  padding: 20px;
}
.product-details-wrapper {
  display: flex;
  gap: 30px;
  max-height: 500px; 
  margin-bottom: 60px;
  background-color: white;
}

.product-image-section {
  width: 50%;
  height: 100%;
}

.product-image-container {
  display: flex;
  gap: 12px;
  height: 100%;
}

.thumbnail-images {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70px;
  overflow-y: auto;
}

.thumbnail-item {
  width: 70px;
  height: 70px;
  border: 1px solid #eee;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.thumbnail-item.active {
  border: 2px solid #66c725;
}

.thumbnail-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-image {
  flex: 1;
  height: 100%;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.product-details-section {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

/* Custom scrollbar for thumbnails and details */
.thumbnail-images::-webkit-scrollbar,
.product-details-section::-webkit-scrollbar {
  width: 4px;
}

.thumbnail-images::-webkit-scrollbar-track,
.product-details-section::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.thumbnail-images::-webkit-scrollbar-thumb,
.product-details-section::-webkit-scrollbar-thumb {
  background: #66c725;
  border-radius: 4px;
}

.product-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #222;
}
.product-tags {
  margin-bottom: 10px;
}
.tag {
  display: inline-block;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  background-color: #66c725;
}
.product-rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.stars {
  color: #ffc107;
  font-size: 20px;
}
.reviews {
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.product-price-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.discounted-price {
  font-size: 20px;
  font-weight: bold;
  color: #e74c3c;
}
.original-price {
  font-size: 15px;
  text-decoration: line-through;
  color: #999;
  margin-left: 15px;
}
.discount {
  font-size: 15px;
  color: #e74c3c;
  margin-left: 15px;
}

.delivery-info,
.shipping-info {
  font-size: 15px;
  color:black;
  margin-bottom: 15px;
}

.product-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.product-options label {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.product-size-dropdown,
.product-option-dropdown {
  width: auto;
  padding: 5px 30px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23999" d="M2 0L0 2h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 8px 8px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.product-size-dropdown:focus,
.product-option-dropdown:focus {
  border-color: #66c725;
  outline: none;
}

.product-quantity {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.quantity-btn {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.quantity-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  margin: 0 10px;
}
.product-options{
  display: flex;
  flex-direction: row;
}
.product-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.add-to-cart-btn {
  background-color: #181515;
  color: white;
  padding: 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  flex-grow: 0;
  width: 85%;
  text-align: center;
}

.add-to-cart-btn:hover {
  background-color: #333;
}

.wishlist-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlist-icon {
  font-size: 24px;
  color: black;
  cursor: pointer;
  transition: color 0.3s ease;
  min-width: 40px;
}

.wishlist-icon:hover {
  color: #ff4444;
}

/* Product Info and Reviews */
.product-info-section,
.product-reviews-section {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.product-info-section h2,
.product-reviews-section h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
}

.product-info-section p,
.product-reviews-section p {
  font-size: 16px;
  color: #555;
}

/* FAQ Section */
.faq-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f9f9f9;
}

.faq-item h2 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.chevron {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.faq-content {
  padding: 10px 0;
  font-size: 14px;
  color: #666;
}

.faq-content p {
  margin: 0;
}

/* Reviews Section */
.review-item {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.review-item:last-child {
  border-bottom: none;
}

.review-item h4 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
}

.review-item p {
  font-size: 16px;
  color: #555;
}

.review-date {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
}
.productbuy-now-btn {
  background: none;
  color: #FF9900;
  border: 1px solid #FF9900;
  padding: 5px 30px;
  font-size: 14px;
  border-radius: 0px;
  font-weight: bold;
  margin-left: 20px;
  cursor: pointer;
}
.productbuy-now-btn:hover {
  background-color: #FF9900;
  color: white;
}

.product-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.description-images{
  display: flex;
  flex-direction: column;
  gap: 0 ;
}
.description-image{
display:flex;
width: 100%;
object-fit: cover;
}


/* Responsive adjustments */
@media (max-width: 1024px) {
  .product-details-wrapper {
    flex-direction: column;
    max-height: none;
    gap: 20px;
  }

  .product-image-section,
  .product-details-section {
    width: 100%;
    height: auto;
  }

  .product-image-container {
    height: 500px;
  }

  .thumbnail-images {
    height: 500px;
  }

  .product-details-section {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .product-image-container {
    height: 400px;
    flex-direction: column-reverse;
  }

  .thumbnail-images {
    width: 100%;
    height: auto;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .thumbnail-item {
    width: 60px;
    height: 60px;
  }

  .main-image {
    height: 300px;
    align-self: flex-start;
  }

  .product-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .add-to-cart-btn {
    width: 90%;
    order: unset;
  }

  .wishlist-icon-container {
    border: 1px solid black;
    padding: 10px;
    width: auto;
    order: unset;
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .product-view-container {
    padding: 0.5rem;
  }

  .product-image-container {
    height: 320px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .main-image {
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-image img {
    width: auto;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .thumbnail-images {
    width: 100%;
    height: auto;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: center;
  }

  .thumbnail-item {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .thumbnail-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .product-price-section {
    flex-wrap: wrap;
    gap: 5px;
  }
  .product-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .add-to-cart-btn {
    width: 85%;
    order: unset;
  }

  .wishlist-icon-container {
    border: none;
    width: auto;
    order: unset;
    margin-top: 0;
  }
}
