
  /* Add smooth transitions */
  .specific-item-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .specific-item-info {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    animation: slideIn 0.3s ease-in-out;
  }
  
  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #eee;
    position: sticky;
    top: 0;
    background: white;
    border-radius: 12px 12px 0 0;
  }
  
  .info-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .close-button:hover {
    background: #f5f5f5;
    color: #333;
  }
  
  .info-content {
    padding: 24px;
  }
  
  .info-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .info-content li {
    padding: 12px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
  }
  
  .info-content li:last-child {
    border-bottom: none;
  }
  
  .info-content strong {
    min-width: 120px;
    color: #666;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Make the modal responsive */
  @media (max-width: 768px) {
    .specific-item-info {
      width: 95%;
      margin: 10px;
    }
  
    th {
      width: 120px;
      font-size: 13px;
    }
  
    td {
      font-size: 13px;
    }
  
    .info-header h2 {
      font-size: 16px;
    }
  }