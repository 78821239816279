.filter-container {
  display: flex;
  gap: 30px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.filter-section {
  flex: 0 0 300px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.products-section {
  flex: 1;
  min-width: 0;
}


.filter-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h3 {
  color: #333;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
}

h4 {
  color: #444;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.clear-all {
  color: #999;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.clear-all:hover {
  color: #333;
}

.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.active-filter {
  background-color: #f5fdef;
  color: #00796b;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.active-filter button {
  background: none;
  border: none;
  color: #00796b;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.active-filter button:hover {
  color: #004d40;
}

.filter-category {
  margin-bottom: 20px;
}

.filter-category .category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.filter-category label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 5px 0;
  font-size: 14px;
  color: #555;
  transition: color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.filter-category label:hover {
  color: #222;
  background-color: #eaeaea;
  border-radius: 4px;
}

input[type="checkbox"] {
  margin-right: 10px;
  accent-color: #28a745; 
}

.toggle-icon {
  color: #888;
  font-size: 18px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.toggle-icon:hover {
  color: #333;
  transform: rotate(180deg);
}

.category-options {
  margin-left: 10px;
  margin-top: 10px;
}

/* Apply Button Styling */
.apply-filters-button {
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-filters-button:hover {
  background-color: #218838;
}
/* Slider Styling */
.horizontal-slider {
  color: #3da956;
  width: 100%;
  height: 6px;
  margin: 10px 0;
}

.thumb {
  height: 16px;
  width: 16px;
  background-color: #28a745;
  cursor: grab;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  transform: translateY(-35%); 
  border: none; 
  outline: none; 
  box-shadow: none; 
}
.thumb:hover {
  background-color: #218838; 
}
.track {
  top: 0;
  bottom: 0;
  background: #28a745;
  border-radius: 9999px;
  opacity: 0.7;
}

.price-range-values {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.keyword-search-input,
.brand-search-input,
.formulation-search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.keyword-search-input:focus,
.brand-search-input:focus,
.formulation-search-input:focus {
  border-color: #28a745;
  outline: none;
}
@media (max-width: 768px) {

  .filter-container {
    flex-direction: column;
  }

  .filter-section {
    flex: none;
    width: 100%;
  }
}

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.selected-filter {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 13px;
  color: #333;
}

.remove-filter {
  cursor: pointer;
  font-size: 12px;
  color: #666;
}

.remove-filter:hover {
  color: #333;
}
